import { makeAutoObservable, runInAction, when } from 'mobx';
import { navigate } from '@reach/router';
import { IDP_TEMP_TOKEN } from 'core/communcation/authTokenService';
import { setLoggedInUserName } from 'core/environment/environmentService';
import { redirect } from 'common/helpers/routerHelper';
import environmentUtils from 'common/utils/environmentUtils';
import {
    authenticateToIdentityProvider,
    authenticateWithPassword,
    createIdentityProviderSession,
    createUserSession,
    getIdentityProviders,
    logout,
    removeAuthenticationToken,
    setAuthenticationToken,
    setLoggedIn,
	fetchRoles,
	setActiveRoles
} from './loginService';

const allowedAuthenticationStates = {
    FullyAuthenticated: 'FullyAuthenticated',
    PreAuthenticated: 'PreAuthenticated',
};

export default class LoginStore {
	roleSelectionVisible = false; 
    authenticationVisible = false;
    activeToken;
    activeAuthenticationType;
    username = '';
    secondFactorAuthenticationInProgress = false;
    identityProviders = [];

    _twoFactorAuthenticationReactionDisposer;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    createSession = async (formData) => {
        const username = formData.username;
        const result = await createUserSession(username);

        if (result.success) {
            this._handleCreateSessionSuccess({ username, ...result.data });
        } else {
            this._handleCreateSessionError(username);
        }
    };

    authenticateWithPassword = async (formData) => {
        const password = formData.password;
        const result = await authenticateWithPassword(password);

        result.success
            ? this._handleAuthenticateWithPasswordSuccess()
            : this._handleAuthenticateWithPasswordError();
    };

    authenticateWithIdentityProvider = async (id) => {
        const { success, data } = await createIdentityProviderSession();
        if (!success) {
            this._handleCreateSessionError();
            return;
        }

        const { session } = data;
        const { success: idpSuccess, data: idpData } =
            await authenticateToIdentityProvider(session, id);

        if (!idpSuccess) {
            this._handleCreateSessionError();
            return;
        }

        const { loginUrl } = idpData;
        window.location = loginUrl;
    };

    logout = async () => {
        try {
            this.rootStore.websocketStore.close('Logout');
            this.rootStore.websocketStore.unsubscribeAll();
            await logout();
        } finally {
            this._setDefaultState();
            removeAuthenticationToken();
            setLoggedIn(false);
            setLoggedInUserName(null);
            this.rootStore.twoFactorAuthStore.setDefault();
        }
    };

    cancelAuthentication = () => {
        this._setDefaultState();
    };

    cancelTwoFactorAuthentication = () => {
        this.logout();
        this.rootStore.websocketStore.close(
            'Second factor authentication operation canceled.',
        );
    };

    getIdentityProviders = async () => {
        const identityProvidersResponse = await getIdentityProviders();
        runInAction(() => {
            this.identityProviders = identityProvidersResponse.data;
        });
    };

    handlePostIdentityProviderLogin = async (userName) => {
        await this.rootStore.environmentStore.loadClientConfigurationExternal();

        const session = localStorage.getItem(IDP_TEMP_TOKEN);
        if (!session) {
            redirect('/');
            return;
        }
        this._setSessionData(userName, session);
        this.username = userName;
        this.activeToken = session;
        setLoggedIn(true);
        this.rootStore.websocketStore.initialize();

		console.log('SAML2 login successful!');

        localStorage.removeItem(IDP_TEMP_TOKEN);
        await this._handleRoleSelection();
		if(!this.roleSelectionVisible) {
			console.log('Role selection not needed, redirecting to main page');
			const mainRoute = environmentUtils.getMainRoute();
			redirect(mainRoute);
		} else {
			console.log('Role selection needed');
		}
    };

    handlePostIdentityProviderLoginError = async () => {
		console.error('SAML2 authentication failed');
        await this.logout();
        this._handleCreateSessionError();
        redirect('/');
    };

    _handleCreateSessionSuccess = ({
        username,
        session,
        authenticationType,
    }) => {
        this._setSessionData(username, session);
        this.username = username;
        this.activeToken = session;
        this.activeAuthenticationType = authenticationType;
        this.authenticationVisible = true;
    };

    _handleCreateSessionError = (username) => {
        const errorTag = username ? 'Login.UsernameError' : 'Login.Failed';
        this.rootStore.notificationsStore.showNotification(
            errorTag,
            null,
            'danger',
        );
        this.rootStore.environmentStore.setEnvDefaults();
        this._setDefaultState();
        setLoggedInUserName('');
    };

    _handleAuthenticateWithPasswordSuccess = () => {
        setLoggedIn(true);
        this._makeTwoFactorReactionHandler();
        this.rootStore.websocketStore.initialize();
    };
	
    _makeTwoFactorReactionHandler = async () => {
        await when(() => this.rootStore.websocketStore.state.connectionOpened);

        this._twoFactorAuthenticationReactionDisposer = when(
            () => this.rootStore.twoFactorAuthStore.state.status,
            this._handleTwoFactorAuthenticationCheck,
        );

        this.rootStore.twoFactorAuthStore.getAuthenticationState();
    };

    _handleTwoFactorAuthenticationCheck = async () => {
        this._twoFactorAuthenticationReactionDisposer();
        if (
            this.rootStore.twoFactorAuthStore.state.status ===
            allowedAuthenticationStates.FullyAuthenticated
        ) {
            await this.rootStore.environmentStore.loadClientConfigurationExternal();
			await this._handleRoleSelection();
            return;
        }
        this.rootStore.twoFactorAuthStore.initializeSecondFactorAuthentication();
        this.secondFactorAuthenticationInProgress = true;
    };

    _handleRoleSelection = async () => {
        try {
            const rolesResponse = await fetchRoles();
            if (rolesResponse && rolesResponse.length > 0) {
                runInAction(() => {
                    this.roleSelectionVisible = true;  // Show role selection
                });
                this.roles = rolesResponse;  // Store roles for use in the form
                return;
            } else {
                console.log('No roles available');
            }
        } catch (error) {
            console.error('Error fetching roles', error);
        }

        // If no roles are available or an error occurs, proceed to the main route
        this._completeLogin();
    };

    _handleAuthenticateWithPasswordError = () => {
        setLoggedIn(false);
        this.rootStore.notificationsStore.showNotification(
            'Login.Failed',
            null,
            'danger',
        );
    };

    _setSessionData = (username, session) => {
        setAuthenticationToken(session);
        setLoggedInUserName(username);
        this.rootStore.environmentStore.setUsername(username);
    };

    _setDefaultState = () => {
        this.authenticationVisible = false;
		this.roleSelectionVisible = false;
        this.activeToken = null;
        this.activeAuthenticationType = null;
        this.username = '';
        this.secondFactorAuthenticationInProgress = false;
    };
	
	setSelectedRoles = async (selectedRoles) => {
		try {
			// Send the selected roles to the backend
			await setActiveRoles(selectedRoles);
			console.log('Roles successfully set:', selectedRoles);
		} catch (error) {
			console.error('Failed to set roles:', error);
			// Handle the error (e.g., show a notification)
			this.rootStore.notificationsStore.showNotification(
				'Failed to set roles. Please try again.',
				null,
				'danger'
			);
			return; // Stop further processing if the roles couldn't be set
		}

		// Hide the role selection form and complete the login process
		this.roleSelectionVisible = false;
		this._completeLogin();
	};

    _completeLogin = () => {
        const mainRoute = environmentUtils.getMainRoute();
        runInAction(() => {
            this.authenticationVisible = false;
            this.roleSelectionVisible = false;
        });
        redirect(mainRoute);
    };
}
