import React, { Suspense, useEffect } from 'react';
import { RoutingContextProvider } from 'configuration/routing/routingParams';
import PageSpinner from 'common/components/spinner/PageSpinner';
import { useStore } from 'core/state/StoreProvider';

const LoadableComponentRoleSelection = React.lazy(() => import('./RoleSelection'));

const LoadableSaml2Login = (props) => {
    const rootStore = useStore();

    useEffect(() => {
        // Create an async function to handle SAML login flow
        const handleLogin = async () => {
            const searchParams = new URLSearchParams(props.location.search);
            const authenticationSuccessful =
                searchParams && searchParams.get('success') === '1';

			console.log(props.location.search);

            if (authenticationSuccessful) {
                const userName = searchParams.get('userName');
                // Await the async login function
                await rootStore.loginStore.handlePostIdentityProviderLogin(userName);
            } else if(props.location.search != '') {
                rootStore.loginStore.handlePostIdentityProviderLoginError();
            }
        };

        // Call the async function inside useEffect
        handleLogin();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.location.search, rootStore]);


    return (
        <RoutingContextProvider routingParams={props}>
            <Suspense fallback={<PageSpinner />}>
                <LoadableComponentRoleSelection />
            </Suspense>
        </RoutingContextProvider>
    );
};

export default LoadableSaml2Login;
