import * as api from 'core/communcation/api';
import {
    IDP_TEMP_TOKEN,
    getAuthToken,
    removeAuthToken,
    setAuthToken,
    setUserLoggedIn,
} from 'core/communcation/authTokenService';
import { getBaseAddress } from 'common/helpers/urlHelper';

export const createUserSession = (username) => {
    removeAuthenticationToken();
    const params = { username: username };
    const loginEndpoint = 'sessions';
    return api.post(loginEndpoint, params);
};

export const authenticateWithPassword = (password) => {
    const authToken = getAuthToken();
    const params = { password: password };
    const authEndpoint = `sessions/${authToken}/authentication`;
    return api.put(authEndpoint, params);
};

export const logout = () => {
    const authToken = getAuthToken();
    if (authToken) {
        const authEndpoint = `sessions/${authToken}`;
        return api.del(authEndpoint);
    }
};

export const fetchRoles = async () => {
    try {
        const response = await api.get('roles/available');
        return response.data;  // The response should contain the array of roles (id and name)
    } catch (error) {
        console.error('Error fetching roles:', error);
        throw error;
    }
};

export const setActiveRoles = async (selectedRoleIds) => {
    try {
        await api.put('roles/active', selectedRoleIds);
    } catch (error) {
        console.error('Error setting active roles:', error);
        throw error;
    }
};

export const setLoggedIn = (state) => {
    setUserLoggedIn(state);
};

export const removeAuthenticationToken = removeAuthToken;
export const setAuthenticationToken = setAuthToken;

export const getIdentityProviders = () => api.get(`saml2/idp`);

export const createIdentityProviderSession = () => {
    const params = { isAuthenticationDeferred: true };
    const loginEndpoint = 'sessions';
    return api.post(loginEndpoint, params);
};

export const authenticateToIdentityProvider = (token, identityProviderId) => {
    localStorage.setItem(IDP_TEMP_TOKEN, token);
    const params = {
        authenticationType: 'saml2',
        idp: identityProviderId,
        returnUrl: `${getBaseAddress()}/saml2login`,
        token: token,
    };
    const authEndpoint = `sessions/${token}/authentication`;
    return api.put(authEndpoint, params);
};
